<template>
	<div class="content-wrap">
		<nav-bar title="合格证查询入口" id="navbar"></nav-bar>
		<div class="wrap-bus">
			<img src="../../assets/shandong/xunmei.png" alt="" @click="gotoXM">
			<img src="../../assets/shandong/haojia.png" alt="" @click="gotoHJ">
		</div>
	</div>
</template>

<script>
import {NavBar} from "vant";

export default {
	name: "shandong-query",
	components: { NavBar },
	methods: {
		gotoXM() {
			window.location.href = 'https://h5.xunmeizaixian.com/#/studyCertificateQuery';
		},
		gotoHJ() {
			window.location.href = 'https://dses.dwjtaq.com/wechat/v2/sdec_wx_cert/index.html';
		},
	}
}
</script>

<style lang="less" scoped>
.content-wrap{
	width: 100%;
	height: 100vh;
	background: url('../../assets/shandong/shandongThree_bj.png') no-repeat;
	background-size: 100% 100%;
	position: relative;
	.wrap-bus{
		:nth-child(1){
			position: absolute;
			top: 125px;
			left: 0;
			width: 100%;
		}
		:nth-child(2){
			position: absolute;
			top: 350px;
			left: 0;
			width: 100%;
		}
	}
}
</style>
